export function sortList(list,sortby,parseAsNumber){
    if(!list){return []}
    try {
        if(parseAsNumber){
            return list.sort((a, b) => (parseInt(a[sortby]) > parseInt(b[sortby])) ? 1 : -1)
        }
        return list.sort(((a, b) => (a[sortby].toUpperCase() > b[sortby].toUpperCase()) ? 1 : -1))
    } catch (error) {
        return list
    }
}

export function getLookupByCategoryAndDesc(category, desc, lookupsByCategory)
{

    try {
        for (let i = 0; i < lookupsByCategory[category].length; i++) {
            if(lookupsByCategory[category][i].lookupdescription===desc)
            {
                return lookupsByCategory[category][i]
            }
        }    
           
    } catch (error) {
        return {}
    }
    return {}
}

export function getLookupById(category, id, lookupsByCategory)
{
    try {
        for (let i = 0; i < lookupsByCategory[category].length; i++) {
            if(lookupsByCategory[category][i].lookupid===id)
            {
                return lookupsByCategory[category][i]
            }
        }    
           
    } catch (error) {
        return {}
    }
    return {}
}