<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
        <spinner :fullPage="true" :isLoading="showSpinner"/> 
    </router-view>
</template>

<script>

import { useLookupStore } from './store/lookups';
import { execPostgres } from './services/postgresService';
import { Auth, Hub } from 'aws-amplify';
import { useUserStore } from './store/users';
import { getLookupByCategoryAndDesc } from './services/listService'
import { mapState } from 'pinia';
import Spinner from './pages/components/Loading.vue'
import { useRouteStore } from './store/routes';

export default {
  data(){
    return{
      setViewBag:()=>{},
      setUserContext:()=>{},
      shouldLinkProfile:false,
      showSpinner:false,
      bhiUserId:''
    }
  },
  computed:{
    ...mapState(useLookupStore, ['lookupListsByCategory'])
  },
  components:{
    Spinner    
  },
  methods:{
    async getSignatureOnFile(bhiUser){        

      const commandConfig = {
        method:'GET',
        resource:'getUserSignature',
        command:'SELECT * from "pdhCare"."get_userSignature"($1)',
        values:[bhiUser]
      }
      const sigs = await execPostgres(commandConfig);

      if(sigs.length>=1) {
        return true
      }
      return false
    },
    handleVisibilityChange(){
      // let hidden
      // if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      //   hidden = "hidden";
      // } else if (typeof document.msHidden !== "undefined") {
      //   hidden = "msHidden";
      // } else if (typeof document.webkitHidden !== "undefined") {
      //   hidden = "webkitHidden";
      // }
      // if (document[hidden]) {
      //   //do nothing
      // } else {
      // }
    },
    parseData(data){
      let retVal = {}
      for (let i = 0; i < data.length; i++) {
          if(!retVal[data[i].lookupcategorydescription]){
              retVal[data[i].lookupcategorydescription]=[]
          } 
          retVal[data[i].lookupcategorydescription].push(data[i])
      }
      const len = Object.keys(retVal)
      for (let i = 0; i < len.length; i++) {
          retVal[len[i]] = retVal[len[i]].sort((a,b)=>(a.lookupdescription > b.lookupdescription) ? 1 : -1)
      }
      return retVal
    },
    async updateUserProfile(cognitoUser) {
      const commandConfig = {
        method:'POST',
        resource:'updateCognitoId',
        command:'Call "public"."update_cognitoId"($1, $2, $3)',
        values:[this.bhiUserId, cognitoUser.attributes.sub, Date.parse(new Date())]
      }
      await execPostgres(commandConfig);

    },
    async updateUserLastLogin(bhiUser) {
      const commandConfig = {
        method:'POST',
        resource:'updateUserLastLogin',
        command:'Call "public"."update_userLastLogin"($1, $2)',
        values:[bhiUser.userid, Date.parse(new Date())]
      }
      await execPostgres(commandConfig);

    },
    async setTheUserContext(bhiUser){
      if(!bhiUser.firstname&&!bhiUser.lastname){
        window.localStorage.clear()
        Auth.signOut()
      }
      this.setUserContext(bhiUser);
    },        
    async redirectBasedOnType(cognitoUser){
      let bhiUser
      try {
          const commandConfig = {
            method:'GET',
            resource:'getUserByCognitoId',
            command:'SELECT * from "public"."get_userByCognitoId"($1)',
            values:[cognitoUser.attributes.sub]
          }
          bhiUser = await execPostgres(commandConfig);
      } 
      catch (error) {
        console.log("error?? ", error)
      }

      if(bhiUser&&bhiUser.length===1) {
        await this.setTheUserContext(bhiUser[0])
        await this.updateUserLastLogin(bhiUser[0])

        const patientLookup = getLookupByCategoryAndDesc('Roles','Patient',this.lookupListsByCategory)
        const providerLookup = getLookupByCategoryAndDesc('Roles','Provider',this.lookupListsByCategory)
        const technicianLookup = getLookupByCategoryAndDesc('Roles','Technician',this.lookupListsByCategory)
        const adminRole = getLookupByCategoryAndDesc('Roles','System Administrator',this.lookupListsByCategory)

        if(bhiUser[0].luusertypeid === patientLookup.lookupid) {  
            //this.$router.push({name:'Pal - Patient Home', params:{userId: bhiUser[0].userid, patientId: bhiUser[0].patientid}}) 
        } 
        else if(bhiUser[0].luusertypeid === providerLookup.lookupid) {
          const hasSig = await this.getSignatureOnFile(bhiUser[0].userid)
          if(hasSig){
              this.$router.push({name:'Palliative Care Home'})             
          } else {
              this.setViewBag({'userId': bhiUser[0].userid});
              this.$router.push({name:"Collect Signature"});
          }
        } 
        else if(bhiUser[0].luusertypeid === technicianLookup.lookupid) {
            this.$router.push({name:'Palliative Care Home'})             
        } 
        else if(bhiUser[0].luusertypeid === adminRole.lookupid) {
            this.$router.push({name:'Palliative Admin Home'})             
        }
      } 
      else {
        if(bhiUser&& bhiUser.length===0){
          alert('Unable to find your user acct')
        } 
        else {
          alert('Looks like we are having some trouble logging you in, please try again.') //TODO  we need to refactor this and figure out what to do 

        }
      }
    },
    async setUserContextOnState(user){
      let bhiUser

      try {
        const commandConfig = {
          method:'GET',
          resource:'getUserByCognitoId',
          command:'SELECT * from "public"."get_userByCognitoId"($1)',
          values:[user.attributes.sub]
        }
        bhiUser = await execPostgres(commandConfig);

      } catch (error) {
        console.log("error?? ", error)
      }
      if(bhiUser&&bhiUser.length===1) {
        await this.setUserContext(bhiUser[0])
      }      
    }    
  },
  async beforeMount(){
      this.showSpinner=true;
      const lookup = useLookupStore();
      const user = useUserStore();
      this.setViewBag = useRouteStore().setViewBag;
      
      if(!user.getWelcomeMessage){
        window.localStorage.clear();
        await Auth.signOut();
        this.$router.push({"name":"Login"});
        this.showSpinner=false;
      }

      this.setUserContext = user.setUserContext;
   
      Hub.listen('auth', async (data) => {
        switch (data.payload.event) {
          case 'signIn':
              try {
                this.showSpinner = true;
                const user = await Auth.currentAuthenticatedUser({bypassCache:true});        
                if(this.shouldLinkProfile){
                  await this.updateUserProfile(user);
                }                

                const commandConfig = {
                  method:'GET',
                  resource:'getCommonLookupsByCategory',
                  command:'SELECT * from "public"."get_commonLookupsByCategory"()',
                  values:[]
                }
                const data = this.parseData(await execPostgres(commandConfig));

                lookup.setLookupListsByCategory(data);

                await this.setUserContextOnState(user);
                await this.redirectBasedOnType(user);
                this.showSpinner = false;
              } 
              catch (error) {
                console.log(`Error ${error}`)
              }
              break;
          case 'confirmSignUp':
              this.bhiUserId = this.$route.query.suId;
              this.shouldLinkProfile = true;
        }
      });
      let visibilityChange;
      if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        visibilityChange = "webkitvisibilitychange";
      }    
      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
      this.showSpinner = false;
  }
};
</script>

