<template>

    <div class='dflex-center-row'>
        <font-awesome-icon @click="handleRemove" class='pointer blue-icon' icon="fa-solid fa-trash-can" />
    </div>
    
</template>

<script>    
    export default {
        name:'Remove',
        methods:{
            handleRemove(){
                this.$emit('broadCastToAllComponents',this.params)
            }
        },
        mounted(){
        },
        components:{
        },
        props:{
            params:{}
        },
    }
    
</script>

