<template>
  <div
    class="dflex-col TextInput"
    :class="`${cssClass} ${isValid?'success':isValid===false?'has-error':''}`"
  >
    <label class="label-position" v-show="label" :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :disabled="disabledFlag"
      v-model="value"
      :placeholder="placeholder"
      @blur="emitData"
      @input="handleEmitData"
    />

    <p class="help-message" v-show="errorMessage || successMessage">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import {useViewBagStore} from '../../store/viewBag';
import {ProcessRules} from './helpers/processRules'

export default {
    computed:{
        ...mapState(useViewBagStore, ['formAction','viewBag'])
    },
    data(){
        return{
            upsertFormNode:()=>{},
            submitForm:()=>{},
            clearFormAction:()=>{},
            registerFormRules:()=>{},
            isValid:undefined,
            value:'',
            timer:undefined,            
            successMessage:'',
            errorMessage:'',
        }
    },
    props:{
            formName: {
                type:String,
                required:true
            },
            id: {
                type: String,
                required: true,
            },
            defaultValue: {
                type: String,
                required:true,
                default:''
            },



            placeholder: {
                type: String,
                default: ''
            },
            rules:{
                type:Array,
                default:()=>[]
            },
            label: {
                type: String,
            },
            disabledFlag:{
                type:Boolean,
                default:false
            },
            cssClass: {
                type: String,
                default: ''
            },
    },
    watch:{
        defaultValue: function(val){
            if(this.value === val){return;}
            this.value = val;
        },
        formAction:function(val){
            if(val && val.action==='force-validation' && val.targetForm === this.formName){
                //then we are doing a form submit.
                //by this time the field has been validated and the viewBag updated.
                this.handleForceValidateField();                
            }
        }
    },
    methods:{
        handleForceValidateField(){
            this.successMessage = this.viewBag[this.formName].__S2FormFields[this.id].sucMsg;
            this.errorMessage = this.viewBag[this.formName].__S2FormFields[this.id].errMsg;
            this.isValid = this.viewBag[this.formName].__S2FormFields[this.id].isValid;
        },
        debounce(func, shouldEmit, timeout = 750){
            if(this.timer){return;}
            this.timer = setTimeout(()=>{func(shouldEmit); this.timer=undefined;}, timeout);
        },
        handleEmitData(){
            this.debounce(this.emitData);
        },
        emitData(){
            let isValid = {isValid:true, sucMsg:'', errMsg:'', shouldSubmitForm:false};  
            if(this.rules.length>0){
                isValid = ProcessRules(this.id, this.viewBag[this.formName], this.value);                
            }
            let updateObj = {...this.viewBag[this.formName].__S2FormFields[this.id]};

            this.successMessage = isValid.sucMsg;
            this.errorMessage = isValid.errMsg;
            this.isValid = isValid.isValid;
            updateObj.isValid = isValid.isValid;
            updateObj.sucMsg = isValid.sucMsg;
            updateObj.errMsg = isValid.errMsg; 
            updateObj.currentValue = this.value;

            this.upsertFormNode(
                this.formName,
                updateObj.keyName,
                updateObj
            )
            if(isValid.shouldSubmitForm){this.submitForm(this.formName, this.viewBag[this.formName].__S2FormType)}
            this.$emit('dataChange', this.value);
            if(isValid.shouldSubmitForm){this.clearFormAction(this.formName)}
        },
        initializeControl(){
            this.successMessage = '';
            this.errorMessage = '';
            this.isValid = null;
            this.upsertFormNode(this.formName, 
                                this.id, 
                                    {
                                        keyName:this.id,
                                        sucMsg:'',
                                        errMsg:'',
                                        isValid:null,
                                        currentValue:this.value, 
                                        elType:this.elType, 
                                        dataPoint:null,
                                        fieldRules:this.rules
                                    });        
        }
    }, 
    beforeMount(){
        const vb = useViewBagStore();
        this.upsertFormNode = vb.upsertFormNode;
        this.submitForm = vb.submitForm;
        this.registerFormRules = vb.registerFormRules;
        this.clearFormAction = vb.clearFormAction;
    },
    mounted(){
        if(this.formName){
            this.value = this.defaultValue;
            this.initializeControl();
        }
    }
}

</script>

<style scoped>
    .label-position{
        top: calc(-1.5 * 1em);
        left: 0;
        margin: 0;
        font-size: 14px;
    }

    .TextInput {
    border-radius: 3px;
    border: 1px solid rgba(93, 106, 131, .5) !important;         
    position: relative;
    margin-bottom: calc(1em * 1.5);
    width: 100%;
    }

    label {
    display: block;
    margin-bottom: 4px;
    width: 100%;
    }

    textarea {
    border-radius: 5px;
    border: 2px solid transparent;
    padding: 8px 8px;
    outline: none;
    background-color: #f2f5f7;
    width: 100%;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    }

    textarea:focus {
    border-color: var(--primary-color) !important;
    }

    .help-message {
    position: absolute;
    bottom: calc(-1.5 * 1em);
    left: 0;
    margin: 0;
    font-size: 14px;
    }

    .TextInput.has-error textarea {
    background-color: var(--error-bg-color) !important;
    color: var(--error-color) !important;
    }

    .TextInput.has-error textarea:focus {
    border-color: var(--error-color) !important;
    }

    .TextInput.has-error .help-message {
    color: var(--error-color) !important;
    }

    .TextInput.success textarea {
    background-color: var(--success-bg-color) !important;
    color: var(--success-color) !important;
    }

    .TextInput.success textarea:focus {
    border-color: var(--success-color) !important;
    }

    .TextInput.success .help-message {
    color: var(--success-color) !important;
    }
</style>