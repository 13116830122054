<template>
    <div>
        <NavBar style="box-shadow: 0 6px 6px -5px black; background-color:#404040 !important;"></NavBar>
        <div class='dflex-center-col' style='justify-content:space-between;'>
            <div class="dflex-center-col pad-bottom-100" style="color:white; box-shadow: 0 6px 6px -5px black; background-color:#404040 !important; ">


                <div class='dflex-center-col'>

                    <div class='dflex-center-row pad-30 space-between'>

                        <s2-button
                            class='mw-150'
                            :click="handleBackClick"
                            buttonText='Back'
                        >
                        </s2-button>
                        <div class='dflex-right-col w-50'>
                            <div class='dflex-right-row'>
                                <h4 class="description clearMargin bold ">Provider:&nbsp;&nbsp;</h4><h5 class="description clearMargin right">{{patientDoctor}}</h5>
                            </div>
                            <div class='dflex-right-row'>
                                <h4 class="description clearMargin bold ">Chart Number:&nbsp;&nbsp;</h4><h5 class="description clearMargin right">{{patientChartNumber}}</h5>
                            </div>
                        </div>
                    </div>
                    <div class='dflex-left-row customPad'>
                        <div class="picture-container clearMargin">
                            <div class="picture">
                                <img src="../assets/images/default-avatar.png" class="picture-src" title="" />
                            </div>
                        </div>
                        <div class='ml-30'>
                            <h3 style='font-size:18px;'>{{ patientName }}</h3>
                            <h5 class="description clearMargin">{{ patientAddress }}</h5>
                            <h5 class="description clearMargin">{{patientEmail}}</h5>
                            <h5 class="description clearMargin">{{patientPhone}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <care-content class='boxShadow' style="height:100%; background-color:white; border-radius:6px; position:relative; margin-top:-100px; min-height:900px; width:98%"></care-content>
            <div style="background-color:#404040 !important; width:100%; padding:100px; margin-top:-100px; height:300px;">
            </div>
        </div>    
    </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import CareContent from './components/Content.vue'
import {mapState} from 'pinia';
import { usePatientStore } from '../store/patients';

export default {
    name:'TabWrapper',
    data(){
        return{
            patientName:'',
            patientAddress:'',
            patientPhone:'',
            patientEmail:'',
            patientDOB:'',
            patientDoctor:'',
            patientChartNumber:'',
            setNavBack:()=>{}
        }
    },
    computed:{
        ...mapState(usePatientStore,['patientIssueHeader','navBack'])
    },
    components:{
        NavBar,
        CareContent,
    },
    watch:{
        patientIssueHeader:function(){
            this.patientName = this.patientIssueHeader[0].patname
            this.patientAddress = `${this.patientIssueHeader[0].street} ${this.patientIssueHeader[0].city} ${this.patientIssueHeader[0].statecode} ${this.patientIssueHeader[0].zipcode}`
            this.patientPhone = this.patientIssueHeader[0].phone
            this.patientEmail = this.patientIssueHeader[0].email
            this.patientDOB = this.patientIssueHeader[0].dob
            this.patientDoctor = this.patientIssueHeader[0].docname
            this.patientChartNumber = this.patientIssueHeader[0].patientidentifier
        }

    },
    methods:{
        async handleBackClick(){
            if(Object.keys(this.navBack).length > 0){
                this.$router.push({name:this.navBack.navTo, params:{patData:{patientId:this.navBack.props}}})
                this.setNavBack({});
            } else {
                this.$router.push({name:'Palliative Care Home'})
            }

        }
    },
    beforeMount(){
        this.setNavBack = usePatientStore().setNavBack;
    },
    mounted(){
    }
}
</script>
<style scoped>
.bold{
    text-align: right;
    font-weight:500;
    color:white !important;
}
.description{
    color: #999;
    font-size:1em;
}
.right{
    text-align: right;
}
.customPad{
    padding-top:0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}
.picture-src{
    height: 98px;
    width: 98px;
}
</style>