import { defineStore, acceptHMRUpdate } from 'pinia'

export const useUserStore = defineStore({
    id:'userStore',
    state:()=> ({
        welcomeString: '',
        userContext:{}
    }),
    getters: {
        getWelcomeMessage:(state)=>{
            return state.welcomeString;
        },
        getUserContext(state){
            if(Object.keys(state.userContext).length>0){
                return state.userContext
            } else {
                let decrypted
                try {
                    decrypted = window.localStorage.getItem('UserContext'), process.env.VUE_APP_LOCAL_STORAGE_ENCRYPTION_SECRET    
                    if(decrypted){
                        try {
                            return JSON.parse(decrypted)
                        } catch (error) {
                            // window.localStorage.clear()
                            // Auth.signOut();
                            // window.location = '/'                    
                        }
                    } else {
                            // window.localStorage.clear()
                            // Auth.signOut();
                            // window.location = '/'                    
                        }
                } catch (error) {
                            // window.localStorage.clear()
                            // Auth.signOut();
                            // window.location = '/'                    
                        }
            }

        }
    },
    actions:{
        setUserContext(obj){
            this.userContext = {...obj};
            this.welcomeString = `${obj.firstname} ${obj.lastname}`
        },
        setWelcomeString(message){
            this.welcomeString = message;
        }

    }
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}