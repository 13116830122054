import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import { Amplify } from 'aws-amplify'
import awsExports from  './aws-exports.js'
Amplify.configure(awsExports)
import { createRouter, createWebHistory } from 'vue-router'
import palRoutes from './routes/palRoutes';
import VueSweetalert2 from 'vue-sweetalert2';
import GlobalComponents from "../src/plugins/globalComponents";
import ShiftTwoComponents from './plugins/shift-two-components';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/phoenix.css';
import "vue-select/dist/vue-select.css";
import VueSignaturePad from 'vue-signature-pad';
import JsonCSV from 'vue-json-csv';

const pinia = createPinia()
pinia.use((context)=>{

  context.store.$subscribe((mutation, state)=>{
      window.localStorage.setItem(context.store.$id, JSON.stringify(state))
  })


})
const router = createRouter({
    history: createWebHistory(),
    routes:palRoutes, 
  })

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faXmark,faCheck, faCircleChevronRight, faCircleChevronLeft, faHospital, faStethoscope, faEllipsisVertical, faArrowRightFromBracket, faGear, faLock, faChevronUp, faChevronDown, faUserPlus, faUser, faUsers, faClipboardList, faFileArrowDown, faTrashCan, faComments, faEnvelope, faBell, faCircleArrowLeft, faPencil, faCircleArrowRight, faSignal, faFlagCheckered, faArrowRightToBracket, faFolderOpen, faShuffle, faPlus, faHand, faArrowUpWideShort, faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons'
import { faSearchengin } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faXmark
            ,faCheck
            ,faCircleChevronRight
            ,faCircleChevronLeft
            ,faHospital
            ,faStethoscope
            ,faEllipsisVertical
            ,faArrowRightFromBracket
            ,faGear
            ,faLock
            ,faChevronUp
            ,faChevronDown
            ,faUserPlus
            ,faSearchengin
            ,faUsers
            ,faClipboardList
            ,faFileArrowDown
            ,faTrashCan
            ,faCircleArrowLeft
            ,faPencil
            ,faCircleArrowRight
            ,faComments
            ,faEnvelope
            ,faBell
            ,faSignal
            ,faFlagCheckered
            ,faArrowRightToBracket
            ,faFolderOpen
            ,faShuffle
            ,faPlus
            ,faHand
            ,faUser
            ,faArrowUpWideShort
            ,faArrowDownShortWide
            )





createApp(App).use(pinia).use(router).use(VueSignaturePad).use(VueSweetalert2).use(GlobalComponents).use(ShiftTwoComponents).component('font-awesome-icon', FontAwesomeIcon).component('downloadCsv', JsonCSV).mount('#app')

