<template>
    <div class="vld-parent">
        <loading :active="isLoading" 
        :can-cancel="false" 
        :is-full-page="fullPage"></loading>
    </div>
</template>

<script>
    // Import component
    import Loading from 'vue3-loading-overlay';
    // Import stylesheet
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    
    export default {
        props:{
            isLoading:Boolean,
            fullPage:Boolean
        },
        data(){
            return{
            }
        },
        components:{
            Loading
        }
    }
</script>