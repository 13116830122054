import Datepicker from '@vuepic/vue-datepicker';
import Spinner from '../pages/components/Loading.vue';
import Nav from '../pages/components/Shift-Grid/editableDataGrid/src/components/Nav'
import DementiaStageRad from '../pages/components/Shift-Grid/editableDataGrid/src/components/DementiaStage.vue'
import NavToLead from '../pages/components/Shift-Grid/editableDataGrid/src/components/NavToLead'
import chkBoxComponent from '../pages/components/Shift-Grid/editableDataGrid/src/components/chkBoxComponent'
import EditUser from '../pages/components/Shift-Grid/editableDataGrid/src/components/EditUser'
import Remove from '../pages/components/Shift-Grid/editableDataGrid/src/components/Remove'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("v-date-picker", Datepicker);
    Vue.component("Spinner", Spinner);
    Vue.component('Nav', Nav);
    Vue.component('DementiaStageRad', DementiaStageRad);
    Vue.component('NavToLead', NavToLead);
    Vue.component('chkBoxComponent', chkBoxComponent);
    Vue.component('EditUser', EditUser);
    Vue.component('Remove', Remove)
  }
};

export default GlobalComponents;
