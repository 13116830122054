<template>
    <div class='dflex-center-row'>
        <font-awesome-icon @click="handleEditUser" class='pointer blue-icon' icon="fa-solid fa-circle-chevron-right" />
    </div>
    
</template>

<script>
    export default {
        name:'EditUser',
        methods:{
            handleEditUser(){
                this.$emit('broadCastToAllComponents',this.params)
            }
        },
        mounted(){
        },
        components:{
        },
        props:{
            params:{}
        },
    }
    
</script>

