<template>
    <div class='dflex-center-row'>
        <font-awesome-icon @click="handleNav" class='pointer blue-icon' icon="fa-solid fa-circle-chevron-right" />
    </div>
    
</template>

<script>
    import { useRouteStore } from '@/store/routes';
    
    export default {
        name:'Nav',
        methods:{
            handleNav(){
                this.setViewBag({patientId: this.params.item.id});
                this.$router.push(
                                    {name:'Pal - Patient Issue'}
                                )
            }
        },
        mounted(){
            this.setViewBag = useRouteStore().setViewBag;
        },
        data(){
            return{
                setViewBag:()=>{}
            }
        },
        components:{
        },
        props:{
            params:{}
        },
    }
    
</script>

