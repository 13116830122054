<template>
    <input :id="`rad-${index}`" type='radio' :checked="isChecked"  @change="handleBroadCast" class='rad-button' v-model="rad"/>
</template>

<script>
export default {
    name:'DementiaStageRad',
    data(){
        return{
            rad:'',
            initialIndex:-1,
            isChecked:null,
        }
    },
    props:{
        params:{
            type:Object
        },
        broadCast:{
            type:String
        },
        index:{
            type:Number            
        },
        defaultValue:{
            type:Array,
            default:()=>[]
        }

    },
    watch:{
        broadCast:function(){

            if(this.initialIndex === Number(this.broadCast))
            {
                this.rad = 'on';
                this.isChecked = 'checked';
            } else {
                this.rad = 'off';
                this.isChecked = null;
            }
        }
    },
    methods:{
        handleBroadCast(){
            if (this.rad==="on") { 
                this.$emit('broadCastToAllComponents',[this.index])    
            }
        }        
    },
    mounted(){
        this.initialIndex = this.index;
        if(this.defaultValue.length>0 && this.defaultValue[0].response[0]  === this.index){
            this.rad = 'on';
            this.isChecked = 'checked';
        } else {
            this.rad = 'off';
            this.isChecked = null;
        }
    }
}
</script>

<style scoped>
    .rad-button{
       cursor: pointer;
       transform: scale(1.5); 
    }
</style>