<template>
    <div class='dflex-center-row'>
        <font-awesome-icon @click="handleNav" class='pointer blue-icon' icon="fa-solid fa-circle-chevron-right" />        
    </div>
    
</template>

<script>
    import { useRouteStore } from '@/store/routes'
    import { execPostgres } from '../../../../../../services/postgresService'
    export default {
        name:'Nav',
        data(){
            return {
                setReferenceNumber:()=>{},
                setViewBag:()=>{}
            }
        },
        methods:{
            async handleNav(){
                this.setReferenceNumber(this.params.item.id);
                const commandConfig = {
                    method:'GET',
                    resource:'getLeadByRef',
                    command:'SELECT * from "pal"."get_leadByReferenceId"($1)',
                    values:[this.params.item.id]
                }
                const leadData = await execPostgres(commandConfig);
                this.setViewBag({lead:{lead:leadData[0]}});

                this.$router.push({name:'Pal - Manage Lead'})
            }
        },
        beforeMount(){
            const store = useRouteStore();
            this.setReferenceNumber = store.setReferenceNumber;
            this.setViewBag = store.setViewBag;
        },
        mounted(){
        },
        components:{
        },
        props:{
            params:{}
        },
    }
    
</script>

