import S2Form from '@/components/shift-two/S2Form';
import S2TextInput from '@/components/shift-two/S2TextInput';
import S2Button from '@/components/shift-two/S2Button';
import S2DropDown from '@/components/shift-two/S2DropDown';
import S2DatePicker from '@/components/shift-two/S2DatePicker';
import S2CheckBox from '@/components/shift-two/S2CheckBox';
import S2TextArea from '@/components/shift-two/S2TextArea';



const ShiftTwoComponents = {
  install(Vue) {
    Vue.component("s2-form", S2Form);
    Vue.component("s2-text-input", S2TextInput);
    Vue.component("s2-button", S2Button);
    Vue.component("s2-drop-down", S2DropDown);
    Vue.component("s2-date-picker", S2DatePicker);
    Vue.component("s2-check-box", S2CheckBox);
    Vue.component("s2-text-area", S2TextArea);

  }
};

export default ShiftTwoComponents;
