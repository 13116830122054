<template>
  <div class="s2-toolbar dflex w-100 align-center">
      <div class='dflex align-center w-30'>
          <font-awesome-icon @click="navigateToHome($route.name)" class='giant-icon pointer' icon="fa-solid fa-hospital" />
          <div class="md-toolbar-section-start ml-10">
            <h3 class="md-title shift-white">{{ $route.name }}</h3>
          </div>
      </div>
      <div class='w-40 pad-10'>
      </div>
      <div class='w-30 dflex justify-right align-center'>
          <div class='mr-15 routeText'>Welcome: {{welcomeString}}</div>
          <div class='pointer' @click="handleSignout">
            <font-awesome-icon class='white-icon' icon="fa-solid fa-arrow-right-from-bracket" />            
          </div>          
      </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapState } from 'pinia';
import { useUserStore } from '../../store/users';

export default {
  components: {
  },
  data() {
    return {
    };
  },
  
  computed: {
    ...mapState(useUserStore, ['welcomeString'])
  },  
  methods: {
    async handleSignout() {
      window.localStorage.clear()
      await Auth.signOut();
      window.location = '/'
    },
    navigateToHome(currentPage){
      if(currentPage !== 'Palliative Care Home'){
        this.$router.push({name:'Palliative Care Home'})
      } 
    }
  },
  beforeMount(){
    useUserStore();
  }
};
</script>

<style scoped>
    .s2-toolbar {
      position: relative;
      z-index: 2;      
      min-height: 56px;
      padding: 10px 15px;  
      border-radius: 0!important;    
    }
    .giant-icon {
        font-size:30px;
        color:white;
    }
    .routeText {
      color:white;
    }

</style>
