<template>
    <div>
        <NavBar style="box-shadow: 0 6px 6px -5px black; background-color:#404040 !important;"></NavBar>
        <div class='dflex-center-col' style='justify-content:space-between;'>
            <div class="dflex-center-col pad-bottom-100" style="color:white; box-shadow: 0 6px 6px -5px black; background-color:#404040 !important; ">
                <div class='dflex-left-row pad-20 space-between'  >
                    <div class='w-20' >
                        <s2-button
                            class='white-icon mw-150'
                            :click="handleBackToHome"
                            buttonText="Back"
                        >
                        </s2-button>
                    </div>            
                    <!-- <div class='dflex-right-row w-20' >
                        <h5 class="description clearMargin">{{patientChartNumber}}</h5><span class='rightText'>:Chart Number</span>
                    </div>             -->

                </div>
            </div>
            <care-content class='boxShadow w-95' style="height:100%; background-color:white; border-radius:6px; position:relative; margin-top:-100px;">

            </care-content>
            <div style="background-color:#404040 !important; width:100%; padding:100px; margin-top:-100px;"></div>
        </div>    
    </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import CareContent from './components/Content.vue'
import { mapState } from 'pinia';
import { usePatientStore } from '../store/patients';

export default {
    components:{
        NavBar,
        CareContent,
    },
    computed:{
        ...mapState(usePatientStore, ["navBack"])
    },
    methods:{
        async handleBackToHome() {
           if(Object.keys(this.navBack).length > 0){
                this.$router.push({name:this.navBack.navTo, params:{patData:{patientId:this.navBack.props}}})
                this.setNavBack({});
            } else {
                this.$router.push({name:'Palliative Care Home'})
            }
        },
    },
    data(){
        return{
            showBack:false,
            patientChartNumber:' -------- ',
            setNavBack:()=>{}
        }
    },
    beforeMount(){
        const pat = usePatientStore();        
        this.setNavBack = pat.setNavBack;
    },
    mounted(){
        this.patientChartNumber = window.localStorage.getItem('chartId')
    }
}
</script>
<style>
</style>