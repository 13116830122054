<template>
    <div>
        <NavBar style="box-shadow: 0 6px 6px -5px black; background-color:#404040 !important;"></NavBar>
        <div class='dflex-center-col' style='justify-content:space-between;'>
            <div class="dflex-center-col pad-bottom-100" style="color:white; box-shadow: 0 6px 6px -5px black; background-color:#404040 !important; min-height:180px ">

                <div class='dflex-center-row pad-30 space-between tablet'>
                    <div class='dflex-left-row w-50'>
                        <div class="picture-container  picture-tablet clearMargin ">
                            <div class="picture ">
                                <img src="../assets/images/default-avatar.png" class="picture-src" title="" />
                            </div>
                        </div>
                        <div class='ml-30'>
                            <h3 style='font-size:18px;'>{{ patientName }}</h3>
                            <h5 class="description smallText clearMargin">{{ patientAddress }}</h5>
                            <h5 class="description smallText clearMargin">{{patientEmail}}</h5>
                            <h5 class="description smallText clearMargin">{{patientPhone}}</h5>
                        </div>
                    </div>

                    <div class='dflex-right-col w-50'>
                        <div class='dflex-right-row'>
                            <h4 class="description clearMargin bold ">Provider:&nbsp;&nbsp;</h4><h5 class="description smallText clearMargin right">{{patientDoctor}}</h5>
                        </div>
                        <div class='dflex-right-row'>
                            <h4 class="description clearMargin bold ">Chart Number:&nbsp;&nbsp;</h4><h5 class="description smallText clearMargin right">{{patientChartNumber}}</h5>
                        </div>
                    </div>
                </div>

                <div class='dflex-center-row pad-30 space-between med-and-larger'>
                    <div class='dflex-left-row w-50'>
                        <div class="picture-container clearMargin">
                            <div class="picture">
                                <img src="../assets/images/default-avatar.png" class="picture-src" title="" />
                            </div>
                        </div>
                        <div class='ml-30'>
                            <h3 style='font-size:18px;'>{{ patientName }}</h3>
                            <h5 class="description clearMargin">{{ patientAddress }}</h5>
                            <h5 class="description clearMargin">{{patientEmail}}</h5>
                            <h5 class="description clearMargin">{{patientPhone}}</h5>
                        </div>
                    </div>

                    <div class='dflex-right-col w-50'>
                        <div class='dflex-right-row'>
                            <h4 class="description clearMargin bold ">Provider:&nbsp;&nbsp;</h4><h5 class="description clearMargin right">{{patientDoctor}}</h5>
                        </div>
                        <div class='dflex-right-row'>
                            <h4 class="description clearMargin bold ">Chart Number:&nbsp;&nbsp;</h4><h5 class="description clearMargin right">{{patientChartNumber}}</h5>
                        </div>
                    </div>
                </div>
            </div>


            <care-content class='boxShadow fullWidth w-80' style="height:100%; background-color:white; border-radius:6px; position:relative; margin-top:-100px; min-height:900px;">

            </care-content>
            <div style="background-color:#404040 !important; width:100%; padding:100px; margin-top:-100px;"></div>
        </div>

         <spinner :fullPage="true" :isLoading="showSpinner"/> 
            
    </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Spinner from '../pages/components/Loading.vue'
import CareContent from './components/Content.vue'
import { Auth } from "aws-amplify";
import { useUserStore } from '../store/users'
import { useRouteStore } from '../store/routes'
import { usePatientStore } from '../store/patients'
import { mapState } from 'pinia'
import { execPostgres } from '../services/postgresService'


export default {
    components:{
        NavBar,
        CareContent,
        Spinner
    },
    watch:{
        patientIssueHeader:function(){
            if(Object.keys(this.patientIssueHeader).length>0){
                this.patientName = this.patientIssueHeader[0].patname
                this.patientAddress = `${this.patientIssueHeader[0].street} ${this.patientIssueHeader[0].city} ${this.patientIssueHeader[0].statecode} ${this.patientIssueHeader[0].zipcode}`
                this.patientPhone = this.patientIssueHeader[0].phone
                this.patientEmail = this.patientIssueHeader[0].email
                this.patientDOB = this.patientIssueHeader[0].dob
                this.patientDoctor = this.patientIssueHeader[0].docname
                this.patientChartNumber = this.patientIssueHeader[0].patientidentifier
            } else {
                //not able to load the header .. log out
                window.localStorage.clear()
                Auth.signOut()
            }

        }

    },
    computed: {
        ...mapState(useUserStore, ["userContext"]),
        ...mapState(useRouteStore, ["currentRoute"]),
        ...mapState(usePatientStore, ["patientIssueHeader"]),
    },
    data(){
        return {
            notifications:[],
            showNotificationsModal:false,
            slideNotificationId:'',
            showBackToHome:false,
            patientName:'',
            patientAddress:'',
            patientPhone:'',
            patientEmail:'',
            patientDOB:'',
            patientDoctor:'',
            patientChartNumber:'',
            showSpinner:false,
            setShowInviteUser:()=>{},
            setRoute:()=>{}
        }
    },
    methods:{
        handleInviteUser(){
            this.setShowInviteUser(false);
        },
        handleBackToHome(){
            this.$router.push({name:'Palliative Care Home', params:{}})
            this.showBackToHome = false
            this.setRoute({navTo:'Palliative Care Home'})
        },
        handleNavigateToPatient(note){
            const body = document.body;
            body.style.height = 'auto';
            body.style.overflowY = 'auto';            
            clearTimeout(this.notificationTimer)
            this.$router.push({name:'Pal - Patient Issue', params:{patData:{patientId:note.patientid}}})
        },
        async handleAck(note){
            const commandConfig = {
                method:'POST',
                resource:'updateAcknowledgeNote',
                command:'Call "pal"."update_acknowledgeNote"($1, $2)',
                values:[note.notificationid, Date.parse(new Date())]
            }
            await execPostgres(commandConfig);

            this.slideNotificationId = note.notificationid
            setTimeout(async() => {
                this.showSpinner = true;
                    await this.getNotificationLongPoll()
                this.showSpinner = false;
            }, 500);
        },
        stampToDate(stamp, includeTime){
            if(!stamp){return}
            const date = new Date(Number(stamp));
            if(!includeTime){
                return((date.getMonth()+1)+
                "/"+date.getDate()+
                "/"+date.getFullYear());
            }
            return((date.getMonth()+1)+
                "/"+date.getDate()+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes());
        },
        handleCloseNotificationsModal(){
            this.showNotificationsModal = false
            const body = document.body;
            body.style.height = 'auto';
            body.style.overflowY = 'auto';            
        },
        handleOpenNotificationsModal(){
            const body = document.body;
            body.style.height = '100vh';
            body.style.overflowY = 'hidden';               
            this.showNotificationsModal = true
        },
        async getNotifications(){

            const commandConfig = {
                method:'GET',
                resource:'getNotificationsByUser',
                command:'SELECT * from "pal"."get_notificationsByUser"($1)',
                values:[this.userContext.userid]
            }
            this.notifications = await execPostgres(commandConfig);
        },
        async getNotificationLongPoll(){
            try {
                clearTimeout(this.notificationTimer)    
                if(this.$route.name === 'Palliative Care Home'){
                    await this.getNotifications()
                    this.notificationTimer = setTimeout(async() => {
                        await this.getNotificationLongPoll()
                    }, 45000);
                        
                }

            } catch (error) {
                clearTimeout(this.notificationTimer)
            }
        },
    },
    beforeMount(){

        useUserStore();

        const route = useRouteStore();
        this.setShowInviteUser = route.setShowInviteUser;
        this.setRoute = route.setRoute;

        usePatientStore();
    },
    async mounted(){
            await this.getNotificationLongPoll()        
    },
    beforeUnmount(){
        clearTimeout(this.notificationTimer)
    }

}
</script>
<style scoped>
.description{
    color: #999;
}
.smallText{
    font-size: small;
}
</style>
