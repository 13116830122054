import { defineStore, acceptHMRUpdate } from 'pinia'

export const useRouteStore = defineStore({
    id:'routeStore',
    state:()=> ({
        route:{},
        showInviteUser:false,
        showAddSupportTeam: false,
        routeMetaData:{},
        referenceNumber:0, 
        viewBag:{}
    }),
    getters: {
    },
    actions:{
        setViewBag(data){
            this.viewBag = data;
        },
        setReferenceNumber(data){
            this.referenceNumber = data;
        },
        setRouteMetaData(data){
            this.routeMetaData = data;
        },
        setRoute(data){
            this.route = data;
        },
        setShowInviteUser(data){
            this.showInviteUser = data;
        },
        setShowAddSupportTeam(data){
            this.showAddSupportTeam = data;
        }
    }
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRouteStore, import.meta.hot))
}