<template>
  <div
    :class="`${label?'s2-dp-wrapper':''}  ${isValid?'success':isValid===false?'has-error':''}`"
  >
    <label class="label-position"  v-show="label" :for="id">{{ label }}</label>

    <v-date-picker
        :state="isValid"
        :id="id"
        textInput
        class="h-44"
        autoApply
        inlineWithInput
        :maxDate="maxDate"
        :enableTimePicker="false"
        :disabled="disabledFlag"
        v-model="value"
        :placeholder="placeholder"
        @update:modelValue="handleEmitData">
    </v-date-picker>


    <p class="help-message" v-show="errorMessage || successMessage">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import {useViewBagStore} from '../../store/viewBag';
import '@vuepic/vue-datepicker/dist/main.css'
import { ProcessRules } from './helpers/processRules'




export default {
    computed:{
        ...mapState(useViewBagStore, ['formAction','viewBag'])
    },
    data(){
        return{
            upsertFormNode:()=>{},
            submitForm:()=>{},
            clearFormAction:()=>{},
            registerFormRules:()=>{},
            isValid:undefined,
            value:'',
            timer:undefined,            
            successMessage:'',
            errorMessage:'',
        }
    },
    props:{
            formName: {
                type:String,
                required:true
            },
            id: {
                type: String,
                required: true,
            },


            placeholder: {
                type: String,
                default: ''
            },
            label:{
                type:String,
                default:''
            },
            maxDate:{
                type:Date,
                default:new Date()
            },
            defaultValue: {
                type: String,
                default:''
            },
            rules:{
                type:Array,
                default:()=>[]
            },
            disabledFlag:{
                type:Boolean,
                default:false
            },
    },
    watch:{
        defaultValue: function(val){
            if(this.value === val){return;}
            this.value = val;
        },
        formAction:function(val){
            if(val && val.action==='force-validation' && val.targetForm === this.formName){
                //then we are doing a form submit.
                //by this time the field has been validated and the viewBag updated.
                this.handleForceValidateField();                
            }
        }
    },
    methods:{
        handleForceValidateField(){
            this.successMessage = this.viewBag[this.formName].__S2FormFields[this.id].sucMsg;
            this.errorMessage = this.viewBag[this.formName].__S2FormFields[this.id].errMsg;
            this.isValid = this.viewBag[this.formName].__S2FormFields[this.id].isValid;
        },
        debounce(func, shouldEmit, timeout = 0){
            if(this.timer){return;}
            this.timer = setTimeout(()=>{func(shouldEmit); this.timer=undefined;}, timeout);
        },
        handleEmitData(){
            this.debounce(this.emitData);
        },
        emitData(){
            let isValid = {isValid:true, sucMsg:'', errMsg:'', shouldSubmitForm:false};  
            if(this.rules.length>0){
                isValid = ProcessRules(this.id, this.viewBag[this.formName], this.value);                
            }
            let updateObj = {...this.viewBag[this.formName].__S2FormFields[this.id]};

            this.successMessage = isValid.sucMsg;
            this.errorMessage = isValid.errMsg;
            this.isValid = isValid.isValid;
            updateObj.isValid = isValid.isValid;
            updateObj.sucMsg = isValid.sucMsg;
            updateObj.errMsg = isValid.errMsg;
            updateObj.currentValue = this.value; 
            
            this.upsertFormNode(
                this.formName,
                updateObj.keyName,
                updateObj
            )
            if(isValid.shouldSubmitForm){this.submitForm(this.formName, this.viewBag[this.formName].__S2FormType)}
            this.$emit('dataChange', this.value);
            if(isValid.shouldSubmitForm){this.$nextTick(()=>{
                this.clearFormAction(this.formName);
            })}
        },
        initializeControl(){
            this.successMessage = '';
            this.errorMessage = '';
            this.isValid = null;
            this.upsertFormNode(this.formName, 
                                this.id, 
                                    {
                                        keyName:this.id,
                                        sucMsg:'',
                                        errMsg:'',
                                        isValid:null,
                                        currentValue:this.value, 
                                        elType:this.elType, 
                                        dataPoint:null,
                                        fieldRules:this.rules
                                    });        
        }
    }, 
    beforeMount(){
        const vb = useViewBagStore();
        this.upsertFormNode = vb.upsertFormNode;
        this.submitForm = vb.submitForm;
        this.registerFormRules = vb.registerFormRules;
        this.clearFormAction = vb.clearFormAction;
    },
    mounted(){
        if(this.formName){
            this.value = this.defaultValue;
            this.initializeControl();
        }
    }
}

</script>

<style scoped>
    .s2-dp-wrapper {
        margin-top:-20px;
    }

    .label-position{
        top: calc(-1.5 * 1em);
        left: 0;
        margin: 0;
        font-size: 14px;
        margin-top:-14px;
    }

    .help-message {
        color:red;
        bottom: calc(-1.5 * 1em);
        left: 0;
        margin: 0;
        font-size: 14px;
    }

</style>