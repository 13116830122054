function ProcessRules(idOfFieldBeingEvaluated, form, currentValue){
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };            
    const validatePhone = (phone) => {
        return String(phone)
            .toLowerCase()
            .match(
            // eslint-disable-next-line
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            );
    };            
    const validateZip = (zip) => {
        return String(zip)
            .toLowerCase()
            .match(
            /(^\d{5}$)|(^\d{5}-\d{4}$)/
            );
    };            
    const processValidationRules = (idOfFieldBeingEvaluated, form, currentValue) => {
            let returnValue = {};

            const basicValidation = (form, fieldToEvaluate, rulesToProcess, currentValue)=>{
                let retVal = {isValid:true, sucMsg:'', errMsg:''}

                let pullTheBreaks = false;
                if(rulesToProcess)
                for (let i = 0; i < rulesToProcess.length; i++) {
                    let val = undefined;
                    const type = form.__S2FormFields[fieldToEvaluate].elType;
                    const dataPoint = form.__S2FormFields[fieldToEvaluate].dataPoint;
                    switch (type) {
                        case 'Object':
                                val = currentValue[dataPoint];
                            break;
                        default:
                            val = currentValue;
                            break;
                    }

                    switch (rulesToProcess[i].type) {
                        case 'required':
                            if(!val ){
                                retVal = {isValid:false, sucMsg:'', errMsg:rulesToProcess[i].errorMessage?rulesToProcess[i].errorMessage:'Value Required'} 
                                pullTheBreaks = true;
                            } else {
                                retVal = {isValid:true, sucMsg:rulesToProcess[i].successMessage?rulesToProcess[i].successMessage:'', errMsg:''}
                            }
                            break;
                        case 'is-email':
                            if(val && !validateEmail(val)){
                                retVal = {isValid:false, sucMsg:'', errMsg:rulesToProcess[i].errorMessage?rulesToProcess[i].errorMessage:'Invalid Email Address'} 
                                pullTheBreaks = true;                                
                            } else {
                                retVal = {isValid:true, sucMsg:rulesToProcess[i].successMessage?rulesToProcess[i].successMessage:'', errMsg:''}
                            }
                            break;
                        case 'is-phone':
                            if(val && !validatePhone(val)){
                                retVal = {isValid:false, sucMsg:'', errMsg:rulesToProcess[i].errorMessage?rulesToProcess[i].errorMessage:'Invalid Phone Number'} 
                                pullTheBreaks = true;
                            } else {
                                retVal = {isValid:true, sucMsg:rulesToProcess[i].successMessage?rulesToProcess[i].successMessage:'', errMsg:''}
                            }
                            break;
                        case 'max-length':
                            if(val && val.length>rulesToProcess[i].max){
                                retVal = {isValid:false, sucMsg:'', errMsg:rulesToProcess[i].errorMessage?rulesToProcess[i].errorMessage:'Max Length Exceeded'} 
                                pullTheBreaks = true;
                            } else {
                                retVal = {isValid:true, sucMsg:rulesToProcess[i].successMessage?rulesToProcess[i].successMessage:'', errMsg:''}
                            }
                            break;
                        case 'min-length':
                            if(val &&  val.length<rulesToProcess[i].min){
                                retVal = {isValid:false, sucMsg:'', errMsg:rulesToProcess[i].errorMessage?rulesToProcess[i].errorMessage:'Min Length Not Met'} 
                                pullTheBreaks = true;
                            } else {
                                retVal = {isValid:true, sucMsg:rulesToProcess[i].successMessage?rulesToProcess[i].successMessage:'', errMsg:''}
                            }
                            break;
                        case 'is-zip':
                            if(val && validateZip(val)){
                                retVal = {isValid:true, sucMsg:rulesToProcess[i].successMessage?rulesToProcess[i].successMessage:'', errMsg:''}
                                pullTheBreaks = true;
                            } else {
                                retVal = {isValid:false, sucMsg:'', errMsg:rulesToProcess[i].errorMessage?rulesToProcess[i].errorMessage:'Invalid Zip Code'} 
                            }
                            break;
                        case 'required-when':
                            retVal = {isValid:true, sucMsg:rulesToProcess[i].successMessage?rulesToProcess[i].successMessage:'', errMsg:''}
                            if(form.__S2FormFields[rulesToProcess[i].fieldToWatch].currentValue === rulesToProcess[i].hasValue && !val){
                                retVal = {isValid:false, sucMsg:'', errMsg:rulesToProcess[i].errorMessage?rulesToProcess[i].errorMessage:'Value Required'}
                                pullTheBreaks = true;
                            }
                            break;                      
                        default:
                            retVal = {isValid:false, sucMsg:'', errMsg:'Unknown Validator'}
                            break;
                    }
                    if(pullTheBreaks)break;
                }
                return retVal; 
            }
            returnValue = basicValidation(form, idOfFieldBeingEvaluated, form.__S2FormFields[idOfFieldBeingEvaluated].fieldRules, currentValue);
            return returnValue;
    }
    const checkForCompletedFields = (form, fieldBeingEvaluatedRightNow)=>{
        //if we are here. then the field is valid and also part of an auto submitting form.
        //so. unfortunately you need to look at all the values to see if they are all passed. if so - force submit          
        let isValid = true;
        const fields = Object.keys(form.__S2FormFields); //each key is a field object
        
        for (let x = 0; x < fields.length; x++) {
            if(fields[x]===fieldBeingEvaluatedRightNow){
                //the current field has not been updated yet so you need to consider its current state. 
                continue;
            } else {
                if(form.__S2FormFields[fields[x]].isValid!==true){
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }
    let isValid =  processValidationRules(idOfFieldBeingEvaluated, form, currentValue);
    
    if(form.__S2FormType==='all-fields'&&isValid.isValid===true){
        isValid.shouldSubmitForm = checkForCompletedFields(form, idOfFieldBeingEvaluated);
    }

    return isValid;
}
export {ProcessRules} 