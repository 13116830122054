import PalCareWrapper from '../views/CareWrapper.vue'
import TabWrapper from '../views/TabWrapper.vue'
import PalNewPatientWrapper from '../views/NewPatientWrapper'
import PatientWrapper from '../views/PatientWrapper'
import { useRouteStore } from '@/store/routes'

const NeedsAssessment = ()=> import("../pages/Needs.vue")
const PalCareHome = ()=> import("../pages/CareHome.vue")
const PalPatientIssue = ()=> import("../pages/PatientIssue.vue")
const PalAddPatient = () => import("../pages/AddPatient.vue")
const PalEditPatient = () => import("../pages/EditPatient.vue")
const PalPatientHome = () => import("../pages/PatientHome.vue")
const PalPatientHistory = ()=> import("../pages/PatientHistory.vue")
const PalManageUsers = () => import("../pages/ManageUsers.vue")
const PalManageLead = () => import("../pages/ManageLead.vue")
const PalMyLeads = () => import("../pages/MyLeads.vue")
const PalReminders = () => import("../pages/Reminders.vue")
const PalLeadHistory = () => import("../pages/LeadHistory.vue")
const PalOpenLeads = () => import("../pages/OpenLeads.vue")
const Login = ()=> import('../pages/Login.vue')
const CollectSignature = () => import("../pages/CollectSignature.vue")
const ProductSelection = () => import("../pages/ProductSelection.vue")
const PalAdminHome = () => import("../pages/AdminHome.vue")
const PalResponseReport = () => import("../pages/ResponseReport.vue")
const PalCompletedReport = () => import("../pages/CompletedReport.vue")
const PatientMapView = () => import("../pages/PatientMapView.vue")
const PatientCareTeamMap = () => import("../pages/PatientCareTeamMap.vue")
const setRouteData = (to,from)=>{
  const store = useRouteStore()
  store.setRouteMetaData({toName:to.name, fromName: from.name})
}
const PalManageTeams = () => import("../pages/ManageTeams.vue")
const PalManageTeamAssignment = ()=>import("../pages/ManageTeamAssignment.vue")

  const palRoutes = [
    {
      path: "/",
      redirect: '/login/:suId?'
    },
    {
      path: "/login/:suId?",
      component: Login,
      name: "Login",
      beforeEnter: (to, from) => {
        setRouteData(to,from);
      }
    },    
    {
      props: true,
      path: "/ProductSelection",
      component: ProductSelection,
      name: "Product Selection",
      beforeEnter: (to, from) => {
        setRouteData(to,from);
      }
    },
    {
      props: true,
      path: '/CollectSignature',
      name: 'Collect Signature',
      component: CollectSignature,
      beforeEnter: (to, from) => {
        setRouteData(to,from);
      }
    },
    {
      path: "/needs",
      component: NeedsAssessment,
      name: "Needs",
      beforeEnter: (to, from) => {
        setRouteData(to,from);
      }
    },
    {
      path: '/PatientWrapper',
      component: PatientWrapper,
      redirect: '/PatientWrapper/PatientHome',
      children: [
        {
          props: true,
          path: "/PatientHome",
          component: PalPatientHome,
          name: "Pal - Patient Home",
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        }
      ]
    },
    {
      path: '/PalCareWrapper',
      component: PalCareWrapper,
      redirect: '/PalCareWrapper/CareHome',
      children: [
        {
          props: true,
          path: 'PalCareHome',
          name: 'Palliative Care Home',
          component: PalCareHome,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },         
        {
          props: true,
          path: 'PalAdminHome',
          name: 'Palliative Admin Home',
          component: PalAdminHome,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },         
        {
          props: true,
          path: 'PalManageusers',
          name: 'Pal - Manage Users',
          component: PalManageUsers,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },       
        {
          props: true,
          path: 'PalManageTeams',
          name: 'Pal - Manage Teams',
          component: PalManageTeams,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },  
        {
          props: true,
          path: 'PalManageTeamAssignment',
          name: 'Pal - Team Assignment',
          component: PalManageTeamAssignment,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },        
        {
          props: true,
          path: 'PalManageLead',
          name: 'Pal - Manage Lead',
          component: PalManageLead,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },         
        {
          props: true,
          path: 'PalMyLeads',
          name: 'Pal - My Leads',
          component: PalMyLeads,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },         
        {
          props: true,
          path: 'PalReminders',
          name: 'Pal - Reminders',
          component: PalReminders,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },         
        {
          props: true,
          path: 'PalLeadHistory',
          name: 'Pal - Lead History',
          component: PalLeadHistory,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },         
        {
          props: true,
          path: 'PalOpenLeads',
          name: 'Pal - Open Leads',
          component: PalOpenLeads,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },
        {
          props: true,
          path: 'PalResponseReport',
          name: 'Pal - Packet Response Report',
          component: PalResponseReport,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },
        {
          props: true,
          path: 'PalCompletedReport',
          name: 'Pal - Packet Completed Report',
          component: PalCompletedReport,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },
        {
          props: true,
          path: 'PalPatientMapView',
          name: 'Pal - Patient Map View',
          component: PatientMapView,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        }
      ]
    },
    {
      path: '/PalNewPatientWrapper',
      component: PalNewPatientWrapper,
      redirect: '/PalCareWrapper/CareHome',
      children: [
        {
          props: true,
          path: 'PalAddPatient',
          name: 'Pal - Add Patient',
          component: PalAddPatient,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },
        {
          props: true,
          path: 'PalEditPatient',
          name: 'Pal - Edit Patient',
          component: PalEditPatient,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },
        {
          props: true,
          path: 'PalPatientCareTeamMap',
          name: 'Pal - Patient Care Team Map',
          component: PatientCareTeamMap,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        }        
      ]
    },
    {
      path: '/TabWrapper',
      component: TabWrapper,
      redirect: '/PalCareWrapper/CareHome',
      children: [
        {
          path: 'PalPatientIssue',
          component: PalPatientIssue,
          props: true,
          name: 'Pal - Patient Issue',
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },
        {
          props: true,
          path: 'PalPatientHistory',
          name: 'Pal - Patient History',
          component: PalPatientHistory,
          beforeEnter: (to, from) => {
            setRouteData(to,from);
          }
        },
      ]
    },
    {
      path: '/:catchAll(.*)',
      redirect: () => {
          return {path:'/'}
      }
   }

  ]

export default palRoutes;