<template>
    <input class='chk-asas' type="checkbox" @change="handleBroadCast" v-model="chkASAS">
</template>

<script>
export default {
    name:'chkBoxComponent',
    data(){
        return {
            chkASAS:false,
            initialIndex:-1
        }
    },
    props:{
        params:{
            type:Object
        },
        broadCast:{
            type:String
        },
        index:{
            type:Number            
        },
        defaultValue:{
            type:Array,
            default:()=>[]
        }        

    },
    methods:{
        handleBroadCast(){
            this.$emit('broadCastToAllComponents',this.index)    
        }        
    },
    mounted(){
        this.initialIndex = this.index;
        
        if(this.defaultValue.length>0){
            for (let i = 0; i < this.defaultValue.length; i++) {
                if(Number(this.defaultValue[i]) === this.index){
                    this.chkASAS = true
                    //this.handleBroadCast()
                }
            }

        } 
    }
}
</script>

<style scoped>
    .chk-asas{
        cursor: pointer;
        transform: scale(1.5);
    }
</style>