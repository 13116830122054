import { defineStore, acceptHMRUpdate } from 'pinia'

export const useViewBagStore = defineStore({
    id:'viewBagStore',
    state:()=> ({
        formAction:undefined,
        viewBag:{}
    }),
    getters: {
        isFormValid:(state)=>{
            return (formName) => {
                if(Object.keys(state.viewBag).includes(formName)===false){return false}
                const form = state.viewBag[formName];
                const elementNamesArray = Object.keys(form.__S2FormFields);

                for (let i = 0; i < elementNamesArray.length; i++) {
                    const element = form.__S2FormFields[elementNamesArray[i]];
                    if(!element.isValid && element.elementHasValidation===true ){ 
                        return false;
                    }
                }                
                return true;
            }
        },
    },
    actions:{
        createForm(formName, formType="on-submit"){
            this.viewBag[formName] = {
                    __S2FormName:formName,
                    __S2FormFields:{},
                    __S2FormType:formType,
                    __S2FormStatus:'initialized'
                    };
        },
        upsertFormNode(formName, keyName, data){
            this.viewBag[formName].__S2FormFields[keyName] = data;
        },
        submitForm(formName, typeOfSubmit){
            this.viewBag[formName].__S2FormStatus = 'submitted';
            this.formAction = {action:typeOfSubmit, targetForm:formName};
        },
        clearFormAction(formName){
            this.viewBag[formName].__S2FormStatus = '';
            this.formAction = undefined;
        },
        forceValidation(formName){
            this.formAction = {action:'force-validation', targetForm:formName};            
        },
    }
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useViewBagStore, import.meta.hot))
}