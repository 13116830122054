<template>
    <div class='dflex-col w-100'>
        <NavBar style="box-shadow: 0 6px 6px -5px black; background-color:#404040 !important;"></NavBar>

        <div class='dflex-center-col' style='justify-content:space-between;'>
            <div class="dflex-center-col pad-bottom-100" style="color:white; box-shadow: 0 6px 6px -5px black; background-color:#404040 !important; min-height:180px ">
                    <div @click="handleOpenNotificationsModal" v-if="notifications&&notifications.length>0" class='pointer boxShadow dflex-center-row mt-30 w-35 alert-warning'>
                        {{notifications.length}} Patient Chart {{notifications.length>1?'Notifications':'Notification'}} Found.
                    </div>
                    <div class='dflex-left-row pad-20  space-between' v-if="showBackToHome"  >
                            <s2-button
                                class="mw-150"
                                :click="handleBackToHome"
                                buttonText="Back"
                            >
                            </s2-button>
                            <s2-button
                                class="mw-150"
                                :click="handleInviteUser"
                                buttonText="Invite User"
                            >
                            </s2-button>
                    </div>
                    <div class='dflex-left-row pad-20  space-between' v-if="showBackToHomeFromTeams"  >
                            <s2-button
                                class="mw-150"
                                :click="handleBackToHome"
                                buttonText="Back"
                            >
                            </s2-button>
                            <s2-button
                                class="mw-150"
                                :click="handleAddTeam"
                                buttonText="Add Team"
                            >
                            </s2-button>
                    </div>
                    <div class='dflex-left-row pad-20  space-between' v-if="showBackToHomeFromLead"  >
                            <s2-button
                                class="mw-150"
                                :click="handleBackToHome"
                                buttonText="Back"
                            >
                            </s2-button>
                    </div>
                    <div class='dflex-left-row pad-20  space-between' v-if="showBackToAdminHome"  >
                            <s2-button
                                class="mw-150"
                                :click="handleBackToAdminHome"
                                buttonText="Back"
                            >
                            </s2-button>
                    </div>
                    <div class='dflex-right-row pad-20' v-if="showToCareHome">
                        <s2-button
                            class="mw-150"
                            :click="handleToCareHome"
                            buttonText="Care Home"
                        >
                        </s2-button>
                    </div> 
                    <div class='dflex-left-row pad-20' v-if="showToMangeTeam">
                        <s2-button
                            class="mw-150"
                            :click="handleToManageTeam"
                            buttonText="Back"
                        >
                        </s2-button>
                    </div>                  
            </div>

            <care-content class='boxShadow fullWidth w-90' style="height:100%; background-color:white; border-radius:6px; position:relative; margin-top:-100px; min-height:900px;">
            </care-content>

            <div style="background-color:#404040 !important; width:100%; padding:100px; margin-top:-100px;"></div>
        </div>
        <div class="dflex-center-row" >
            <div class="overlay" :style="`display:${showNotificationsModal ?'flex':'none'}; align-items:center; justify-content:center; z-index:6;`">
                <div class="assessmentFlyoutMenu dflex-top-col" style='align-items:center; position:relative; width:600px; height:800px;' v-show="showNotificationsModal">
                    <div class='dflex-center-row pad-10 w-100' style="background-color:#404040; color:white; justify-content:space-between; height:40px;">
                        <span>Notifications</span>
                        <font-awesome-icon @click="handleCloseNotificationsModal" class='pointer' icon="fa-solid fa-xmark" />
                    </div>
                    <div class='dflex-center-col w-100'>
                    <div :class="`dflex-center-col w-90 pad-20 ${note.notificationid===slideNotificationId?'animate-left-move':''}`" style="justify-content:flex-start; min-height:150px" v-for="(note,index) in notifications" :key="index">
                        <div v-if="notifications.length>0" class='w-100' style='height:90%'>
                            <div class="dflex-left-row" style="align-items:baseline;">
                                <span class="header">Date:</span> <span class='text'>{{stampToDate(note.createddate)}}</span>   
                                <span class="ml-20 header">Patient:</span> <span class='text'>{{note.patientname}}</span>   
                            </div>
                            <div class="dflex-left-row">
                                <span class='notes'>{{note.notetext}}</span>   
                            </div>                            
                        </div>                                
                        <div v-if="notifications.length>0"  class='w-100' style="height:10%;">
                            <div class='dflex-right-row pointer' style="align-items:flex-end;">
                                <!-- <BellCheck @click="handleAck(note)" fillColor="#012169"/> -->
                                <font-awesome-icon @click="handleNavigateToPatient(note)" class='ml-20 blue-icon' icon="fa-solid fa-circle-chevron-right" />        
                            </div>
                        </div>
                    </div>
                    <div class='mt-60 dflex-center-col' v-if="notifications.length===0" :key="0" >
                        <!-- <BellOff :size="40" fillColor="#7a7b7c"/> -->
                        <span >No Notifications At This Time.</span>
                    </div>
                    </div>

                </div>
            </div>
        </div>
        <spinner :fullPage="true" :isLoading="showSpinner"/>             
    </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import CareContent from './components/Content.vue'
import Spinner from '../pages/components/Loading.vue'
import { mapState } from 'pinia'
import { useUserStore } from '../store/users'
import { useRouteStore } from '../store/routes'
import { execPostgres } from '../services/postgresService'
import { getLookupByCategoryAndDesc } from '../services/listService'
import { useLookupStore } from '../store/lookups'

export default {
    components:{
        NavBar,
        CareContent,
        Spinner
    },
    watch:{
        routeMetaData:{
            deep:true,
            handler(val){
                this.showBackToHome = val.toName === 'Pal - Manage Users'
                this.showBackToHomeFromTeams = (val.toName === 'Pal - Manage Teams')
                this.showBackToHomeFromLead = val.toName === 'Pal - Manage Lead' || val.toName === 'Pal - My Leads' || val.toName === 'Pal - Open Leads' || val.toName === 'Pal - Reminders' || val.toName === 'Pal - Lead History'
                || val.toName === 'Pal - Packet Response Report' || val.toName === 'Pal - Packet Completed Report' || val.toName === 'Pal - Patient Map View'
                
                const adminLookup = getLookupByCategoryAndDesc('Roles','System Administrator',this.lookupListsByCategory)
                if(this.userContext.luusertypeid === adminLookup.lookupid && val.toName === 'Palliative Care Home') {
                    this.showBackToAdminHome = true;
                }
                else {
                    this.showBackToAdminHome = false;
                }
                this.showToCareHome = (val.toName === 'Palliative Admin Home');  
                this.showToMangeTeam = (val.toName === 'Pal - Team Assignment');
            }
        } 
    },

    computed: {
        ...mapState(useUserStore, ['userContext']),
        ...mapState(useRouteStore, ['routeMetaData', 'route']),
        ...mapState(useLookupStore, ['lookupListsByCategory'])
    },
    data(){
        return {
            showSpinner:false,
            notifications:[],
            showNotificationsModal:false,
            slideNotificationId:'',
            showBackToHome:false,
            showBackToHomeFromLead:false,
            showToCareHome: false,
            showBackToAdminHome: false,
            showBackToHomeFromTeams: false,
            showToMangeTeam: false,
            setShowInviteUser:()=>{},
            setShowAddSupportTeam: ()=>{}        
        }
    },
    methods:{
        handleInviteUser(){
            this.setShowInviteUser(true);
        },
        handleToCareHome() {
            this.showBackToHomeFromLead = false
            this.showToCareHome = false
            this.showBackToAdminHome = false
            this.$router.push({name:'Palliative Care Home'})
        },
        handleBackToAdminHome() {
            this.$router.push({name:'Palliative Admin Home'})
        },
        async handleBackToHome(){
            const getReturnToRoute = () => {
                for (let i = 0; i < this.route.length; i++) {
                    if(this.route[i].navTo === this.routeMetaData.toName){
                        return {route:this.route[i].returnTo, params:this.route[i].params};
                    }
                }
                return {route:'Palliative Care Home'} //fall back
            }
            const returnTo = getReturnToRoute() 
            this.showBackToHome = false
            this.showBackToHomeFromLead = false
            this.showToCareHome = false
            this.showBackToAdminHome = false
            this.$router.push({name:returnTo.route, params:returnTo.params})
        },
        handleNavigateToPatient(note){
            const body = document.body;
            body.style.height = 'auto';
            body.style.overflowY = 'auto';            
            clearTimeout(this.notificationTimer)
            this.$router.push({name:'Pal - Patient Issue', params:{patData:{patientId:note.patientid}}})
        },
        async handleAck(note){

            const commandConfig = {
                method:'POST',
                resource:'updateAcknowledgeNote',
                command:'Call "pal"."update_acknowledgeNote"($1, $2)',
                values:[ note.notificationid, Date.parse(new Date()) ]
            }
            await execPostgres(commandConfig);

            this.slideNotificationId = note.notificationid
            setTimeout(async() => {
                this.showSpinner = true;
                    await this.getNotificationLongPoll()
                this.showSpinner = false;
            }, 500);
        },
        stampToDate(stamp, includeTime){
            if(!stamp){return}
            const date = new Date(Number(stamp));
            if(!includeTime){
                return((date.getMonth()+1)+
                "/"+date.getDate()+
                "/"+date.getFullYear());
            }
            return((date.getMonth()+1)+
                "/"+date.getDate()+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes());
        },
        handleCloseNotificationsModal(){
            this.showNotificationsModal = false
            const body = document.body;
            body.style.height = 'auto';
            body.style.overflowY = 'auto';            
        },
        handleOpenNotificationsModal(){
            const body = document.body;
            body.style.height = '100vh';
            body.style.overflowY = 'hidden';               
            this.showNotificationsModal = true
        },
        async getNotifications(){
            const commandConfig = {
                method:'GET',
                resource:'getNotificationsByUser',
                command:'SELECT * from "pal"."get_notificationsByUser"($1)',
                values:[this.userContext.userid]
            }
            this.notifications = await execPostgres(commandConfig);
        },
        async getNotificationLongPoll(){
            try {
                clearTimeout(this.notificationTimer)    
                if(this.$route.name === 'Palliative Care Home'){
                    await this.getNotifications()
                    this.notificationTimer = setTimeout(async() => {
                        await this.getNotificationLongPoll()
                    }, 45000);
                        
                }

            } catch (error) {
                clearTimeout(this.notificationTimer)
            }
        },
        handleAddTeam() {
            this.setShowAddSupportTeam(true);
        },
        handleToManageTeam() {
            this.$router.push({name:'Pal - Manage Teams'})
        }
    },
    beforeMount(){
        const route = useRouteStore();
        this.setShowInviteUser = route.setShowInviteUser;
        this.setShowAddSupportTeam = route.setShowAddSupportTeam;
    },
    async mounted(){
        await this.getNotificationLongPoll()  

        const adminLookup = getLookupByCategoryAndDesc('Roles','System Administrator',this.lookupListsByCategory)
        if(this.userContext.luusertypeid === adminLookup.lookupid && this.routeMetaData.toName === 'Palliative Care Home') {
            this.showBackToAdminHome = true;
        }
        else {
            this.showBackToAdminHome = false;
        }
        this.showToCareHome = (this.routeMetaData.toName === 'Palliative Admin Home');
    },
    beforeUnmount(){
        clearTimeout(this.notificationTimer)
    }

}
</script>
<style>
</style>