import axios from 'axios';
import { Auth } from 'aws-amplify';

  export async function execPostgres(config) {
      const mtd = config.method.toUpperCase();

      const resource = 'exec';
      let env = 'test'
      const url = window.location.href
      
      const urlArray = url.split('//')
      const siteArray = urlArray[1].split('/')
      const site = siteArray[0]
      if(site === 'app.pdhcare.com'){
          env = 'prod'
      }

      let baseUrl = 'https://a1ziojxvb8.execute-api.us-east-2.amazonaws.com' //test by default;
      if (env==='prod') {
        baseUrl = "https://9oxniyg2v5.execute-api.us-east-2.amazonaws.com"; 
      }

      let res;
      let apiEndpoint = {
        method:mtd,
        url:`${baseUrl}/p/${resource}`
      }

      if(mtd==='GET'){
        const v = JSON.stringify({"command":config.command, "values":config.values});
        res = await axios.get(apiEndpoint.url,{params:{d:v}, headers: {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}})
      } else if(mtd==="POST"){
        const v = JSON.stringify({"command":config.command, "values":config.values});
        res = await axios.post(apiEndpoint.url,{d:v}, {headers: {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}})
      }
      
      return res.data.rows      
  }