<template>
  <div
    class="drop-down-wrapper"
    :class="`${isValid?'success':isValid===false?'has-error':''}`"
  >
    <label class="label-position" v-show="label" :for="id">{{ label }}</label>
    <v-select
        class='drop-down'
        :id="id"
        :type="'select'"
        :clearable="false"
        v-model="value"
        :options="options"
        :label="displayField"
        :placeholder="placeholder"
        @option:selected="handleEmitData"
        :disabled="disabledFlag">
    </v-select> 

    <p class="help-message" v-show="errorMessage || successMessage">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import {useViewBagStore} from '../../store/viewBag';
import vSelect from 'vue-select';
import { mapState } from 'pinia';
import { ProcessRules } from './helpers/processRules'


export default {
    computed:{
        ...mapState(useViewBagStore, ['formAction','viewBag'])
    },
    components:{
        vSelect
    },
    data(){
        return{
            upsertFormNode:()=>{},
            submitForm:()=>{},
            clearFormAction:()=>{},
            registerFormRules:()=>{},
            isValid:undefined,
            value:undefined,
            timer:undefined,   
            elType:'Object',  
            successMessage:'',
            errorMessage:'',
        }
    },
    props:{
            formName: {
                type:String,
                required:true
            },
            id: {
                type: String,
                required: true,
            },
            defaultValue: {
                type: Object,
                required:true
            },
            displayField: {
                type: String,
                required:true,
                default:''
            },
            options: {
                type: Array,
                required:true
            },


            placeholder: {
                type: String,
                default: ''
            },
            rules:{
                type:Array,
                default:()=>[]
            },
            label: {
                type: String,
            },
            disabledFlag:{
                type:Boolean,
                default:false
            },
    },
    watch:{
        defaultValue: function(val){
            if(this.value === val){return;}
            this.value = val;
        },
        formAction:function(val){
            if(val && val.action==='force-validation' && val.targetForm === this.formName){
                //then we are doing a form submit.
                //by this time the field has been validated and the viewBag updated.
                this.handleForceValidateField();                
            }
        }
    },
    methods:{
        handleForceValidateField(){
            this.successMessage = this.viewBag[this.formName].__S2FormFields[this.id].sucMsg;
            this.errorMessage = this.viewBag[this.formName].__S2FormFields[this.id].errMsg;
            this.isValid = this.viewBag[this.formName].__S2FormFields[this.id].isValid;
            this.setCSS(this.isValid);
        },
        debounce(func, shouldEmit, timeout = 0){
            if(this.timer){return;}
            this.timer = setTimeout(()=>{func(shouldEmit); this.timer=undefined;}, timeout);
        },
        handleEmitData(){
            this.debounce(this.emitData);
        },
        emitData(){
            let isValid = {isValid:true, sucMsg:'', errMsg:'', shouldSubmitForm:false};  
            if(this.rules.length>0){
                isValid = ProcessRules(this.id, this.viewBag[this.formName], this.value);                
            }
            let updateObj = {...this.viewBag[this.formName].__S2FormFields[this.id]};

            this.successMessage = isValid.sucMsg;
            this.errorMessage = isValid.errMsg;
            this.isValid = isValid.isValid;
            updateObj.isValid = isValid.isValid;
            updateObj.sucMsg = isValid.sucMsg;
            updateObj.errMsg = isValid.errMsg; 
            updateObj.currentValue = this.value;
            
            this.setCSS(isValid.isValid);

            this.upsertFormNode(
                this.formName,
                updateObj.keyName,
                updateObj
            )
            if(isValid.shouldSubmitForm){this.submitForm(this.formName, this.viewBag[this.formName].__S2FormType)}
            this.$emit('dataChange', this.value);
            if(isValid.shouldSubmitForm){this.clearFormAction(this.formName)}
        },
        initializeControl(){
            this.successMessage = '';
            this.errorMessage = '';
            this.isValid = null;
            this.upsertFormNode(this.formName, 
                                this.id, 
                                    {
                                        keyName:this.id,
                                        sucMsg:'',
                                        errMsg:'',
                                        isValid:null,
                                        currentValue:this.value, 
                                        elType:this.elType, 
                                        dataPoint:this.displayField,
                                        fieldRules:this.rules
                                    });        
        },
        setCSS(isValid){
            const spans = document.getElementById(this.id).querySelectorAll('span');
            const divs = document.getElementById(this.id).querySelectorAll('div');
            const inputs = document.getElementById(this.id).querySelectorAll('input');

            if(isValid){
                for (let a = 0; a < spans.length; a++) {
                    spans[a].classList.remove('error-drop-down')
                    spans[a].classList.add('success-drop-down')
                }
                for (let b = 0; b < divs.length; b++) {
                    divs[b].classList.remove('error-drop-down')
                    divs[b].classList.add('success-drop-down')
                }
                for (let z = 0; z < inputs.length; z++) {
                    inputs[z].classList.remove('error-drop-down')
                    inputs[z].classList.add('success-drop-down')
                }
            } else if(isValid===false) {
                for (let a = 0; a < spans.length; a++) {
                    spans[a].classList.add('error-drop-down')
                    spans[a].classList.remove('success-drop-down')
                }
                for (let b = 0; b < divs.length; b++) {
                    divs[b].classList.add('error-drop-down')
                    divs[b].classList.remove('success-drop-down')
                }
                for (let z = 0; z < inputs.length; z++) {
                    inputs[z].classList.add('error-drop-down')
                    inputs[z].classList.remove('success-drop-down')
                }
            } else if(isValid === undefined){
                for (let a = 0; a < spans.length; a++) {
                    spans[a].classList.remove('success-drop-down')
                }
                for (let b = 0; b < divs.length; b++) {
                    divs[b].classList.remove('success-drop-down')
                }
                for (let z = 0; z < inputs.length; z++) {
                    inputs[z].classList.remove('success-drop-down')
                }
            }
        }
    }, 
    beforeMount(){
        const vb = useViewBagStore();
        this.upsertFormNode = vb.upsertFormNode;
        this.submitForm = vb.submitForm;
        this.registerFormRules = vb.registerFormRules;
        this.clearFormAction = vb.clearFormAction;
    },
    mounted(){
        if(this.formName){
            this.value = this.defaultValue;
            this.initializeControl();
        }
    }
}

</script>

<style scoped>
    .label-position{
        position: absolute;
        top: calc(-1.5 * 1em);
        left: 0;
        margin: 0;
        font-size: 14px;
    }
    .drop-down{
        height: 44px !important;
        background-color: #f2f5f7 !important;
        border-radius: var(--vs-border-radius)
    }
    .drop-down-wrapper {
    border-radius: 3px;
    height:44px;
    border: 1px solid rgba(93, 106, 131, .5) !important;         
    position: relative;
    margin-bottom: calc(1em * 3);
    width: 100%;
    }

    input {
    border-radius: 5px;
    border: 2px solid transparent;
    padding: 8px 8px;
    outline: none;
    background-color: #f2f5f7;
    width: 100%;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    }

    input:focus {
        border-color: var(--primary-color);
    }

    .help-message {
        color:red;
        position: absolute;
        bottom: calc(-1.5 * 1em);
        left: 0;
        margin: 0;
        font-size: 14px;
    }

    .TextInput.has-error input {
    background-color: var(--error-bg-color);
    color: var(--error-color);
    }

    .TextInput.has-error input:focus {
    border-color: var(--error-color);
    }

    .TextInput.has-error .help-message {
    color: var(--error-color);
    }

    .TextInput.success input {
    background-color: var(--success-bg-color);
    color: var(--success-color);
    }

    .TextInput.success input:focus {
    border-color: var(--success-color);
    }

    .TextInput.success .help-message {
    color: var(--success-color);
    }
</style>


