<template>
  <button
    :disabled="disabled"
    @click="handleEmitFormData"
    :class="`white-icon dflex button ${disabled ?'s2default':color}`"
    :style="`height:${height}px; width:${width}px; cursor:${disabled?'not-allowed':'pointer'}`"
  >{{buttonText}}
  </button>
</template>

<script>
import { mapState } from 'pinia';
import { useViewBagStore } from '../../store/viewBag';
import { ProcessRules } from './helpers/processRules'

export default {
  name: "S2Button",
  components: {},
  data() {
    return {
      submitForm:()=>{},
      forceValidation:()=>{},
      clearFormAction:()=>{},
      upsertFormNode:()=>{}
    };
  },
  computed:{
      ...mapState(useViewBagStore, ["viewBag"])    
  },
  props: {
    click: {
      type: Function,
      default: () => {}
    },

    buttonText:{
      type:String,
      default:''
    },
    height: {
      type: Number,
      default: 40
    },
    width: {
      type: Number,
      default: 100
    },
    color: {
      type: String,
      default: "s2success"
    },
    disabled:{
      type:Boolean,
      default:false
    },
    submitForms:{
      type:Array
    }
  },
  methods:{
    handleEmitFormData(){
      let retArray = [];
      if(!this.submitForms){
        this.click();
      } else {
        /*
          ok. if the button is not part of a form handle above.

          if the button is part of a form. you are handling a couple situations

          the inital form submittion

          subsequent form submittions
        */
        for (let i = 0; i < this.submitForms.length; i++) {
            let validCount = 0;
            const tmpFields = Object.keys(this.viewBag[this.submitForms[i]].__S2FormFields);
            for (let z = 0; z < tmpFields.length; z++) {
              const result = ProcessRules(tmpFields[z], this.viewBag[this.submitForms[i]], this.viewBag[this.submitForms[i]].__S2FormFields[tmpFields[z]].currentValue);
              if(result.isValid===true){validCount++;}

              let updateObj = {...this.viewBag[this.submitForms[i]].__S2FormFields[tmpFields[z]]};
              updateObj.isValid = result.isValid;
              updateObj.sucMsg = result.sucMsg;
              updateObj.errMsg = result.errMsg; 
              this.upsertFormNode(
                  this.submitForms[i],
                  updateObj.keyName,
                  updateObj
              )
            }
            retArray.push({"form":this.submitForms[i], "formIsValid":validCount===tmpFields.length, "formFields":this.viewBag[this.submitForms[i]].__S2FormFields});
            this.forceValidation(this.submitForms[i]);
            setTimeout(() => {
              this.clearFormAction(this.submitForms[i]);
            }, 1000);
        }
        this.click(retArray);
      }
    }
  },
  beforeMount(){
    const vb = useViewBagStore();
    this.submitForm = vb.submitForm;
    this.forceValidation = vb.forceValidation;
    this.clearFormAction = vb.clearFormAction;
    this.upsertFormNode = vb.upsertFormNode;
  }
};
</script>

<style>

</style>
