import { defineStore, acceptHMRUpdate } from 'pinia'
import { Auth } from 'aws-amplify';

export const useLookupStore = defineStore({
    id:'lookupStore',
    state:()=> ({
        lookupLists:{},
        lookupListsByCategory:{}      
    }),
    getters: {
        getLookupLists:(state)=>{
            return state.lookupLists;
        },
        getLookupListsByCategory:(state)=>{
            if (Object.keys(state.lookupListsByCategory).length > 0){
                return state.lookupListsByCategory
            } else {
                window.localStorage.clear()
                Auth.signOut();
                window.location = '/'                    
            }
        }
    },
    actions:{
        setLookupLists(obj){
            this.lookupLists = obj;
        },
        setLookupListsByCategory(obj){
            this.lookupListsByCategory = obj
        }
    }
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLookupStore, import.meta.hot))
}