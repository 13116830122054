<template>
  
    <div class='dflex justify-center align-center'>

        <form autocomplete="off"  :id="id" class='dflex-col' @submit.prevent>
            <slot name="inputSlot"></slot>
        </form>

        <spinner :fullPage="true" :isLoading="showSpinner"/> 
    </div>

</template>

<script>
import { mapState } from 'pinia';
import {useViewBagStore} from '../../store/viewBag';
import { ProcessRules } from './helpers/processRules'
import Spinner from '../../pages/components/Loading.vue'

export default {
    components:{
        Spinner
    },
    props:{
        id:{
            type:String,
            required:true,
        },
        autoSubmitOn:{
            type:String,
            default:'on-submit'
        }
    },
    computed:{
        ...mapState(useViewBagStore, ['formAction','viewBag'])
    },
    watch:{
        formAction:function(val){
            if(val && val.targetForm === this.id){
                if(val.action==='all-fields'){
                    this.handleAutoSubmit(this.viewBag[this.id])
                }
                
            }
        }        
    },

    data(){
        return{
            showSpinner:false,
            isFormValid:false,
            formHeight:0,
            updateReqWhenTriggers:()=>{},
            upsertFormNode:()=>{},
            triggerFieldsToUpdate:()=>{},
            clearFormAction:()=>{}
        }
    },
    methods:{
        handleAutoSubmit(form){
            this.$emit('formSubmit', {
                    formIsValid:true,
                    formFields:{...form.__S2FormFields}
                })     
        },
        handleFormSubmit(form){
            this.showSpinner = true;
            let result = {isValid:false, sucMsg:'', errMsg:'', shouldSubmitForm:false};
            for (let i = 0; i < form.__S2FormFields.length; i++) {
                if(form.__S2FormFields[i].isValid===null){
                    result = ProcessRules(form.__S2FormFields[i].keyName, form, form.__S2FormFields[i].currentValue);
                }
            }
            if(result.shouldSubmitForm){
                this.$emit('formSubmit', {
                    formIsValid:true,
                    formFields:{...form.__S2FormFields}
                })                
            }
            this.clearFormAction(this.id);
            this.showSpinner = false;
        }
    },
    beforeMount(){
        this.showSpinner = true;
        const vb = useViewBagStore();
        vb.createForm(this.id, this.autoSubmitOn);
        this.updateReqWhenTriggers = vb.updateReqWhenTriggers;
        this.upsertFormNode = vb.upsertFormNode;
        this.triggerFieldsToUpdate = vb.triggerFieldsToUpdate;
        this.clearFormAction = vb.clearFormAction;
        this.showSpinner = false;
    },
    mounted(){
        this.formHeight = document.getElementById(this.id).offsetHeight;
    }
}
</script>

<style scoped>
    .validating{
        font-size: large;
    }
</style>