import { defineStore, acceptHMRUpdate } from 'pinia'

export const usePatientStore = defineStore({
    id:'patientStore',
    state:()=> ({
        patientContext:{},
        patientIssueHeader:{},
        navBack:{}
    }),
    getters: {
        getPatientContext:(state)=>{
            return state.patientContext;
        },
        getPatientIssueHeader:(state)=>{
            return state.patientIssueHeader;
        },
        getNavBack:()=>{
            return this.navBack;
        }
    },
    actions:{
        setPatientContext(obj){
            this.patientContext = obj;
        },
        setPatientIssueHeader(obj){
            this.patientIssueHeader = {...obj};
        },
        setNavBack(obj){
            this.navBack = obj;
        }
    }
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePatientStore, import.meta.hot))
}